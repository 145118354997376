import React from 'react';

const ContactInformation = ({ contractor }) => {
	if (!contractor) return null;

	const { contact_details } = contractor;
	const {
		public_email_address,
		public_phone_number,
		public_mailing_address,
		public_city,
		public_state,
		public_zip_code,
	} = contact_details;

	return (
		<div className='contact'>
			<h3>Contact Information</h3>
			<div className='email'>
				<a href={`mailto:${public_email_address}`}>
					<i className='fa fa-envelope' aria-hidden='true' />
					<span>{public_email_address}</span>
				</a>
			</div>
			<div className='phone'>
				<a href={`tel:${public_phone_number}`}>
					<i className='fa fa-phone' aria-hidden='true' />
					<span>{public_phone_number}</span>
				</a>
			</div>
			<div className='address'>
				<a
					href={`http://maps.google.com/?q=${public_mailing_address}, ${public_city}, ${public_state}`}>
					<i className='fa fa-map-marker' aria-hidden='true' />
					<span>
						{public_mailing_address} <br />
						{public_city}, {public_state} {public_zip_code}
					</span>
				</a>
			</div>
		</div>
	);
};

export default ContactInformation;
