// Constants.js
const getApiBaseUrl = () => {
	const { hostname } = window.location;

	if (hostname.includes('localhost')) {
		return 'http://localhost:3000/api/v1';
	} else if (hostname.includes('qual')) {
		return 'https://manage.txssc.qual.txstate.edu/api/v1';
	} else {
		return 'https://manage.txssc.txstate.edu/api/v1';
	}
};

export const API_BASE_URL = getApiBaseUrl();

export const PENDING_LITIGATION_NOTE =
	'* Consultants must stipulate “yes” or “no” regarding whether they have current or pending civil litigation related to school safety or security consulting services. Districts are responsible for verifying this information and determining if it impacts the work to be performed on behalf of the district.';

export const AUDIT_NOTE =
	'* Consultants must stipulate “yes” or “no” regarding whether they have experience conducting school safety and security audits in accordance with the Texas Education Code, Chapter 37. “No” does not imply that consultants do not have experience conducting school safety and security audits in other states or in a different capacity. Consultants must meet all required qualifications to be listed under the Audit Discipline.';
