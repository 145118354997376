import React from 'react';
import Masthead from '../../components/Masthead';

const SBTA = () => {
	return (
		<div className='disciplines'>
			<Masthead
				heading1='Disciplines'
				heading2='School Behavioral Threat Assessment (SBTA) Discipline'
			/>

			<p className='left-full'>
				An SBTA Consultant may consult public school districts, charter school
				districts and junior colleges on the setup and function of their threat
				assessment teams, assist with risk or threat analysis, and the
				behavioral intervention case management process.
			</p>

			<div className='callout'>
				<h3>
					SBTA Basic Training <em>(all applicants)</em>
				</h3>
				<p>
					<u>All individuals</u> seeking registration for the SBTA discipline
					must meet the training requirements in either option 1 or option 2
					below (not both).
				</p>

				<h4 className='no-margin-bottom'>Option 1</h4>
				<ul>
					<li>
						<a
							href='https://sslp.txssc.txstate.edu/'
							alt='TxSSC SBTA Course'
							target='_blank'
							rel='noopener noreferrer'>
							Behavioral Threat Assessment (Prerequisite)
						</a>{' '}
						and{' '}
						<a
							href='https://sslp.txssc.txstate.edu/'
							alt='TxSSC DTA Course'
							target='_blank'
							rel='noopener noreferrer'>
							Digital Threat Assessment (Prerequisite)
						</a>
					</li>
				</ul>
				<h4 className='no-margin-bottom'>Option 2</h4>
				<ul>
					<li>
						16 hours of other school behavioral threat assessment training (can
						include one of the TxSSC classes in option 1)
					</li>
				</ul>
				<h4 className='no-margin-bottom'>Evidence Required:</h4>
				<ul className='no-margin-top'>
					<li>Course certificates uploaded to Registry software</li>
				</ul>
			</div>

			<h3>Professional Qualifications</h3>
			<p className='left-full'>
				Consultants may choose to submit proof to add the following discipline
				qualifications to enhance their public profile. Once verified by TxSSC,
				consultant qualifications will be searchable and viewable on the
				Registry. While these qualifications are not mandatory, consultants may
				want to consider one or more of them as they may be used by districts to
				select consultants from the Registry.
			</p>

			<p className='left-full'>
				Districts are encouraged to use these additional qualifications to help
				decide which consultant may be best to perform services for the
				district.
			</p>

			<div className='callout'>
				<h3>Professional License</h3>
				<ul>
					<li>Licensed Professional Counselor (LPC)</li>
					<li>Licensed Clinical Social Worker (LCSW)</li>
					<li>Licensed School Psychologist</li>
					<li>Licensed Psychiatrist</li>
					<li>Licensed Specialist in School Psychology (LSSP)</li>
					<li>Professional School Counselor</li>
					<li>
						Texas Commission on Law Enforcement (TCOLE) Peace Officer or
						equivalent from another state or federal
					</li>
				</ul>
				<h4 className='no-margin-bottom'>Evidence Required:</h4>
				<ul className='no-margin-top'>
					<li>License uploaded to Registry software</li>
				</ul>
			</div>

			<div className='callout'>
				<h3>Training and Certifications</h3>
				<ul>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://texreg.sos.state.tx.us/public/readtac$ext.TacPage?sl=R&app=9&p_dir=&p_rloc=&p_tloc=&p_ploc=&pg=1&p_tac=&ti=37&pt=7&ch=221&rl=11'
							alt='Mental Health Officer Certificate from Texas Commission on Law Enforcement'>
							Mental Health Officer Certificate from Texas Commission on Law
							Enforcement
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://tahn.org/training/'
							alt='Crisis Negotiation Training (40 hours total)'>
							Crisis Negotiation Training
						</a>{' '}
						(40 hours total)
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'></i>
						<a
							href='https://www.atapworldwide.org/page/certificationexam'
							alt='Association of Threat Assessment Professionals Certified Threat Manager (CTM) certification'>
							Association of Threat Assessment Professionals Certified Threat
							Manager (CTM) certification
						</a>
					</li>
				</ul>
				<h4 className='no-margin-bottom'>Evidence Required:</h4>
				<ul className='no-margin-top'>
					<li>Certificate uploaded to Registry software</li>
				</ul>
			</div>
		</div>
	);
};

export default SBTA;
