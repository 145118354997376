import { useCallback } from 'react';

const useContractorFilter = (
	allContractors,
	{
		nameFilter,
		organizationFilter,
		serviceFilter,
		regionFilter,
		licenseFilter,
		otherFilter,
		trainingCertFilter,
	}
) => {
	const filterByName = useCallback(
		(contractor) => {
			if (!nameFilter) return true;
			return (
				contractor.public_last_name
					.toLowerCase()
					.includes(nameFilter.toLowerCase()) ||
				contractor.public_first_name
					.toLowerCase()
					.includes(nameFilter.toLowerCase())
			);
		},
		[nameFilter]
	);

	const filterByOrganization = useCallback(
		(contractor) => {
			if (!organizationFilter) return true;
			const orgName = contractor.contact_details.public_org_affiliation || '';
			return orgName.toLowerCase().includes(organizationFilter.toLowerCase());
		},
		[organizationFilter]
	);

	const filterByService = useCallback(
		(contractor) => {
			if (!serviceFilter) return true;
			return contractor.approved_disciplines.includes(serviceFilter);
		},
		[serviceFilter]
	);

	const filterByRegion = useCallback(
		(contractor) => {
			if (!regionFilter) return true;
			return contractor.contact_details.esc_regions.includes(regionFilter);
		},
		[regionFilter]
	);

	const filterByTrainingCert = useCallback(
		(contractor) => {
			if (!trainingCertFilter) return true;
			const allTrainings = [];
			if (contractor.discipline_details.audit) {
				allTrainings.push(
					...(contractor.discipline_details.audit.audit_training_and_certs ||
						[])
				);
			}
			if (contractor.discipline_details.eop) {
				allTrainings.push(
					...(contractor.discipline_details.eop.eop_training_and_certs || [])
				);
			}
			if (contractor.discipline_details.sbta) {
				allTrainings.push(
					...(contractor.discipline_details.sbta.sbta_training_and_certs || [])
				);
			}
			return allTrainings.some((tc) =>
				tc.toLowerCase().includes(trainingCertFilter.toLowerCase())
			);
		},
		[trainingCertFilter]
	);

	const filterByLicense = useCallback(
		(contractor) => {
			if (!licenseFilter) return true;
			const allLicenses = [];
			if (contractor.discipline_details.audit) {
				allLicenses.push(
					...(contractor.discipline_details.audit.audit_licenses || [])
				);
			}
			if (contractor.discipline_details.eop) {
				allLicenses.push(
					...(contractor.discipline_details.eop.eop_licenses || [])
				);
			}
			if (contractor.discipline_details.sbta) {
				allLicenses.push(
					...(contractor.discipline_details.sbta.sbta_licenses || [])
				);
			}
			return allLicenses.some((l) =>
				l.toLowerCase().includes(licenseFilter.toLowerCase())
			);
		},
		[licenseFilter]
	);

	const filterByOther = useCallback(
		(contractor) => {
			if (!otherFilter) return true;
			if (contractor.discipline_details.other) {
				const allOther =
					contractor.discipline_details.other.other_services || [];
				return allOther.some((o) =>
					o.toLowerCase().includes(otherFilter.toLowerCase())
				);
			}
			return false;
		},
		[otherFilter]
	);

	const applyAllFilters = useCallback(() => {
		return allContractors.filter(
			(contractor) =>
				filterByName(contractor) &&
				filterByOrganization(contractor) &&
				filterByService(contractor) &&
				filterByRegion(contractor) &&
				filterByTrainingCert(contractor) &&
				filterByLicense(contractor) &&
				filterByOther(contractor)
		);
	}, [
		allContractors,
		filterByName,
		filterByOrganization,
		filterByService,
		filterByRegion,
		filterByTrainingCert,
		filterByLicense,
		filterByOther,
	]);

	return applyAllFilters();
};

export default useContractorFilter;
