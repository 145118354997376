import React from 'react';
import Masthead from '../../components/Masthead';

const ConsultantAppeal = () => {
	return (
		<div className='background'>
			<Masthead heading1='Background' heading2='Consultant Appeal Process' />
			<p className='left-full'>
				Consultants may be suspended from the Registry for administrative or
				substantive reasons. Consultants will receive an email notification of
				suspension via the Registry software. For complete information on
				policies and procedures, refer to the Registry Policies and Procedures
				Manual.
			</p>

			<h2>Administrative Suspension</h2>

			<div className='callout'>
				<h3>
					A consultant may be administratively suspended from the Registry for
					minor technical deficiencies, including, but not limited to the
					following:{' '}
				</h3>
				<ul>
					<li>
						Failing to provide necessary documentation to verify continued
						compliance with licensing, certifications, or Continuing Education
						Unit (CEU) requirements as required to remain on the Registry;{' '}
					</li>
					<li>
						Misrepresentation as defined in the TxSSC Registry policies; or
					</li>
					<li>
						Verification, based on the majority of evidence, of complaints
						received by the TxSSC that would make the consultant no longer
						eligible for the Registry.{' '}
					</li>
				</ul>
			</div>

			<p className='left-full'>
				Administrative suspension may be immediate upon evidence of minor
				technical deficiencies and may include exclusion from the Registry
				search.
			</p>

			<h2>Administrative Appeal</h2>

			<p className='left-full'>
				A registered consultant seeking to request an appeal due to being
				suspended from the Registry by a TxSSC SSR Supervisor for a violation of
				this policy may appeal such decision to the Associate Director of SSR.
				The consultant shall submit a request for appeal via the Registry
				software within thirty (30) calendar days of the suspension. The
				Associate Director of SSR shall review the appeal and render a decision
				as to uphold or overturn the suspension within fifteen (15) business
				days from the date of receipt of the written appeal request. The
				decision of the Associate Director of SSR shall be final.
			</p>

			<p className='left-full'>
				Failing to correct an administrative deficiency after six (6) months of
				the administrative suspension shall be considered a substantive policy
				violation and will result in a substantive suspension.
			</p>

			<h2>Substantive Suspension</h2>

			<p className='left-full'>
				A consultant may be substantively suspended from the Registry for major
				policy violation or evidence of disqualifying factors, including, but
				not limited to the following:
			</p>

			<div className='callout'>
				<h3>
					A consultant may be substantively suspended from the Registry for
					major policy violation or evidence of disqualifying factors,
					including, but not limited to the following:{' '}
				</h3>
				<ul>
					<li>
						Falsification of documents utilized to determine eligibility for the
						Registry;{' '}
					</li>
					<li>
						Litigation involving school safety and security consulting services,
					</li>
					<li>Disqualifying criminal history, </li>
					<li>Sex offender registry status,</li>
					<li>Status on the TEA Do Not Hire Registry,</li>
					<li>
						Negative status on the State Board for Educator Certification (SBEC)
						educator certificate search, or equivalent, and
					</li>
					<li>
						Failure to correct an administrative deficiency after six (6) months
						of administrative suspension.
					</li>
				</ul>
			</div>

			<h2>Substantive Appeal</h2>

			<p className='left-full'>
				A consultant denied for substantive reasons may file an appeal within
				thirty (30) calendar days via the Registry software. The appeal will be
				reviewed by a TxSSC School Safety Readiness Supervisor within fifteen
				(15) business days of receipt of the appeal. The consultant will receive
				notification of the appeal determination via the Registry software.
			</p>

			<p className='left-full'>
				A consultant may appeal the decision of the TxSSC School Safety
				Readiness Supervisor within thirty (30) calendar days of the date the
				appeal denial is sent via the Registry software. The appeal will be
				reviewed by a TxSSC School Safety Readiness Associate Director within
				fifteen (15) business days of receipt of the appeal. The consultant will
				receive notification of the appeal determination via the Registry
				software.
			</p>

			<p className='left-full'>
				If the School Safety Readiness Associate Director denies the appeal, the
				consultant may appeal the decision to the Director of the Texas School
				Safety Center within thirty (30) calendar days via the Registry
				software.
			</p>

			<p className='left-full'>
				The Director shall review the appeal and render a decision to uphold or
				overturn the suspension within fifteen (15) business days from the date
				of the appeal request. The decision of the Director is final and
				binding.
			</p>
		</div>
	);
};

export default ConsultantAppeal;
