import { useState } from 'react';

const useExpander = (initialState = {}) => {
	const [expandedSections, setExpandedSections] = useState(initialState);

	const toggleSection = (index) => {
		setExpandedSections((prev) => ({
			...prev,
			[index]: !prev[index],
		}));
	};

	return [expandedSections, toggleSection];
};

export default useExpander;
