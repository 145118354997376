import React from 'react';
import Masthead from '../../components/Masthead';

const DistrictOverview = () => {
	return (
		<div className='about'>
			<Masthead heading1='About' heading2='District Overview' />

			<p className='left-full'>
				As mandated by{' '}
				<a
					href='https://statutes.capitol.texas.gov/docs/ED/htm/ED.37.htm'
					target='_blank'
					rel='noopener noreferrer'>
					<b>Texas Education Code 37.2091</b>
				</a>
				, any individual providing school safety or security consulting services
				to school districts, open enrollment charter schools, or junior colleges
				in Texas must register with the Texas School Safety Center (TxSSC).
				These services include providing advice, information, recommendations,
				data collection, or safety and security audit services relevant to
				school safety and security.
			</p>

			<p className='left-full'>
				<b>
					Beginning September 1, 2023, a school district must confirm that a
					person is included in the Texas School Safety and Security Consultant
					Registry (Registry) before the district may engage the person to
					provide school safety or security consulting services to the district.
				</b>
			</p>

			<p className='left-full'>
				A company or organization may not apply for inclusion on the Registry,
				only individuals. The Registry is designed to assist districts with
				finding vetted safety and security consultants willing to provide school
				safety and security consulting services in the region where the district
				is located. Districts can use the search filters to locate consultants
				by name, services provided, professional license, or additional training
				and certifications.
			</p>

			<div className='callout'>
				<h3>
					As part of the process for being accepted onto the Registry, each
					consultant has been vetted for the following:
				</h3>
				<ul>
					<li>Disqualifying criminal history,</li>
					<li>Sex offender registry status,</li>
					<li>Status on the TEA Do Not Hire Registry</li>
					<li>
						Negative status on the State Board for Educator Certification (SBEC)
						educator certificate search, or equivalent, and
					</li>
					<li>
						Litigation involving school safety and security consulting services.
					</li>
				</ul>
			</div>

			<p className='left-full'>
				The TxSSC does not endorse any consultant on the Registry. It is the
				responsibility of the hiring district to ensure the consultant can
				perform the work needed by the district to the standards required by the
				district. Districts are responsible for evaluating performance,
				products, insurance documentation, and checking references.
			</p>

			<p className='left-full'>
				If a district believes a consultant should not be included on the
				Registry for a disqualifying reason, the district can file a complaint
				with TxSSC via{' '}
				<a href='/contact/complaint'>
					<b>Contact Us</b>
				</a>
				. For more information on the background check process and the Summary
				of Disqualifiers, refer to{' '}
				<a href='/background/requirements'>
					<b>Background Check Requirements</b>
				</a>{' '}
				page.
			</p>
		</div>
	);
};

export default DistrictOverview;
