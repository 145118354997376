import React from 'react';

const Bar = ({ percentage }) => {

	return (
		<div className='bar'>
			<svg width='100' height='100' viewBox='0 0 100 100' className='bar-graph'>
				<rect x='0' y='0' width='100' height='100' fill='#e5e5e5' />
				<rect
					className='bar-graph-fill'
					x='0'
					y='0'
					width='100'
					height={percentage}
					fill='#501215'
					transform='translate(0, 100) scale(1, -1)'
				/>
			</svg>
		</div>
	);
};

export default Bar;
