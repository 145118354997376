import React from 'react';
import Masthead from '../../components/Masthead';

const Other = () => {
	return (
		<div className='disciplines'>
			<Masthead
				heading1='Disciplines'
				heading2='Other Safety and Security Discipline'
			/>

			<p className='left-full'>
				The Other Safety and Security Consultant offers services outside of the
				purview of the Audit, EOP, or SBTA safety and security disciplines. This
				might include, but is not limited to, packaged training providers, alarm
				and video installers, and environmental health consultants. An
				individual providing services under the Other Safety and Security
				Discipline may not offer or complete services in the Audit, EOP, or SBTA
				disciplines without registering for those disciplines.
			</p>
		</div>
	);
};

export default Other;
