import React, { useState } from 'react';
import disqualifiersInfographic from '../../assets/images/disqualifiers_infographic.jpg';
import Masthead from '../../components/Masthead';

const Requirements = () => {
	const [openModal, setOpenModal] = useState(false);

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};
	return (
		<div className='background'>
			<Masthead
				heading1='Background'
				heading2='Background Check Requirements'
			/>

			<p className='left-full'>
				All individuals applying for the Texas School Safety and Security
				Consultant Registry will undergo a background check, consisting of the
				following:
			</p>

			<ol>
				<li>
					Complete and pass a fingerprint-based criminal history background
					check. See Summary of Disqualifiers table below for more information.
				</li>
				<li>
					Be checked against the national and state sex offender registry.
				</li>
				<li>
					Be checked against the{' '}
					<a
						href='https://tea.texas.gov/texas-educators/investigations/do-not-hire-registry'
						alt='TEA Do Not Hire Registry'
					>
						TEA Do Not Hire Registry
					</a>
				</li>
				<li>
					If a certified educator, be checked against the{' '}
					<a
						href='https://secure.sbec.state.tx.us/sbeconline/virtcert.asp'
						alt='SBEC educator certificate search'
					>
						SBEC educator certificate search
					</a>{' '}
					for negative information.
				</li>
				<li>
					For any pending litigation involving school safety and security
					consulting services, provide an explanation for review.
				</li>
			</ol>

			<p className='image extra-large-image' onClick={handleOpenModal}>
				<img
					src={disqualifiersInfographic}
					alt='Summary of Disqualifiers for the Texas School Safety and Security Consultant Registry'
					style={{ padding: '0' }}
				/>
				<span className='subtext'>
					<i className='fa fa-plus' aria-hidden='true'></i> Click to enlarge
				</span>
			</p>

			<p className='left-full'>
				The above graphic summary is a tool to understand the list of Registry
				disqualifiers so that Registry applicants may check themselves against
				general standards before beginning the application process. It was not
				created to be exhaustive of all specific offenses that disqualify an
				applicant from the Registry.
			</p>

			<div className='callout'>
				<h3>
					The following statutes are the complete list of the Registry criminal
					history disqualifiers. Deferred adjudications for the listed offenses
					will count as convictions for disqualification purposes.
				</h3>

				<ul>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://texreg.sos.state.tx.us/public/readtac$ext.TacPage?sl=R&app=9&p_dir=&p_rloc=&p_tloc=&p_ploc=&pg=1&p_tac=&ti=37&pt=1&ch=35&rl=4'
							alt='Texas Administrative Code, Title 37, Part 1, Chapter 35, Subchapter A, Rule §35.4 – Guidelines for Disqualifying Criminal Offenses'
						>
							Texas Administrative Code, Title 37, Part 1, Chapter 35,
							Subchapter A, Rule §35.4 – Guidelines for Disqualifying Criminal
							Offenses
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://texreg.sos.state.tx.us/public/readtac$ext.TacPage?sl=R&app=9&p_dir=&p_rloc=&p_tloc=&p_ploc=&pg=1&p_tac=&ti=19&pt=7&ch=249&rl=16'
							alt='Texas Administrative Code, Title 19, Part 7, Chapter 249, Subchapter B, RULE §249.16 - Eligibility of Persons with Criminal History for a Certificate under Texas Occupations Code, Chapter 53, and Texas Education Code, Chapter 21'
						>
							Texas Administrative Code, Title 19, Part 7, Chapter 249,
							Subchapter B, RULE §249.16 - Eligibility of Persons with Criminal
							History for a Certificate under Texas Occupations Code, Chapter
							53, and Texas Education Code, Chapter 21
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://statutes.capitol.texas.gov/docs/ED/htm/ED.22.htm'
							alt='Texas Education Code Sec. 22.0834. CRIMINAL HISTORY RECORD INFORMATION REVIEW OF CERTAIN CONTRACT EMPLOYEES'
						>
							Texas Education Code Sec. 22.0834. CRIMINAL HISTORY RECORD
							INFORMATION REVIEW OF CERTAIN CONTRACT EMPLOYEES
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://statutes.capitol.texas.gov/docs/ED/htm/ED.22.htm'
							alt='Texas Education Code Sec. 22.085. EMPLOYEES AND APPLICANTS CONVICTED OF OR PLACED ON DEFERRED ADJUDICATION COMMUNITY SUPERVISION FOR CERTAIN OFFENSES.'
						>
							Texas Education Code Sec. 22.085. EMPLOYEES AND APPLICANTS
							CONVICTED OF OR PLACED ON DEFERRED ADJUDICATION COMMUNITY
							SUPERVISION FOR CERTAIN OFFENSES.
						</a>
					</li>

					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://statutes.capitol.texas.gov/docs/oc/htm/oc.53.htm'
							alt='Texas Occupations Code Sec. 53.021 AUTHORITY TO REVOKE, SUSPEND, OR DENY LICENSE'
						>
							Texas Occupations Code Sec. 53.021 AUTHORITY TO REVOKE, SUSPEND,
							OR DENY LICENSE
						</a>
					</li>
				</ul>
			</div>

			<p className='left-full'>
				An applicant is not eligible to be placed on the Registry if they do not
				meet the background check requirements. If at any time, a registered
				consultant fails to continue to meet background eligibility
				requirements, they will be subject to suspension or revocation from the
				Registry.
			</p>

			{openModal ? (
				<div className='modal' onClick={handleCloseModal}>
					<div className='modal-content'>
						<p className='image x-extra-large-image'>
							<img
								src={disqualifiersInfographic}
								alt='Map of all ESC Regions in the state of Texas'
							/>
							<span className='subtext'>
								<i className='fa fa-minus' aria-hidden='true'></i> Click to
								shrink
							</span>
						</p>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default Requirements;
