import React from 'react';
import Masthead from '../../components/Masthead';
import useExpander from '../../hooks/useExpander';

const Consultants = () => {
	const [expandedSections, toggleSection] = useExpander();

	const faqSections = [
		{
			title:
				'How do I know if I am required to be on the Texas School Safety and Security Consultant Registry (Registry)?',
			content: `
          If you are a person who provides school safety or security consulting services in Texas, you are required to be on the Registry. School safety or security consulting services includes any service provided to a school district, institution of higher education, district facility, or campus by a person consisting of advice, information, recommendations, data collection, or safety and security audit services relevant to school safety and security, regardless of whether the person is paid for those services.
        `,
		},
		{
			title:
				'What are examples of professionals that are NOT required to register with the TxSSC?*',
			content: `
					The following professionals are not required to register:
					<br />
          <ul>
            <li>District Employees (Employees carrying out their assigned job duties for their employing district.)</li>
            <li>Education Service Center Specialists (Employees carrying out their assigned job duties for their assigned region.)</li>
            <li>School Marshals</li>
            <li>Guardians</li>
            <li>Private Security Guards</li>
            <li>School Based Law Enforcement Personnel </li>
            <li>DPS Certified School Safety Certification Instructors</li>
            <li>Architects</li>
            <li>First Responder with Jurisdiction</li>
          </ul>
          <p>
						* This list is not exhaustive. Please reach out to <a href='mailto:txssc_registry@txstate.edu'><b>txssc_registry@txstate.edu</b></a> if you have questions regarding the requirements for being on the Registry
					</p>
      `,
		},
		{
			title: 'Can I register my business and include my employees?',
			content: `
          Companies or organizations cannot apply for inclusion on the Registry. The Registry exists to fulfill the requirements of 
            <a href='https://statutes.capitol.texas.gov/docs/ED/htm/ED.37.htm' target='_blank' rel='noopener noreferrer'>
              <b>Texas Education Code 37.2091</b></a> by providing a registry of <b>persons</b> providing school safety and security consulting services in Texas.
          Each person performing school safety or security consulting services, whether on behalf of their self, a company, or an organization must become a registered consultant regardless of whether the person is paid for their services.
      `,
		},
		{
			title: 'Does the TxSSC recommend the consultants on the Registry?',
			content: `
          The TxSSC does not endorse or recommend any consultants on the Registry. We carefully screen applicants against specific background disqualifiers in line with the Texas Education Agency and the Texas Department of Public Safety. 
      `,
		},
		{
			title: 'What is a Discipline?',
			content: `
          Registry applicants are required to choose one or more disciplines for which they would like to provide consulting services to school districts, charter schools, or public junior colleges in Texas.  The basic training requirements are specific to the Discipline being sought and documentation must be provided to show the applicant has met all requirements. The TxSSC has pre identified optional qualifications that applicants can demonstrate their skillset to searchers if supporting documentation is provided by the applicant.
      `,
		},
		{
			title: 'What Disciplines are available on the Registry?',
			content: `
          The Registry offers four separate Disciplines. The three distinct disciplines include <a href='/disciplines/audit' target='_blank' rel='noopener noreferrer'><b>Audit</b></a>, Multi-Hazard Emergency Operations Plan (<a href='/disciplines/eop' target='_blank' rel='noopener noreferrer'><b>EOP</b></a>) and School Behavioral Threat Assessment (<a href='/disciplines/sbta' target='_blank' rel='noopener noreferrer'><b>SBTA</b></a>). The comprehensive discipline, <a href='/disciplines/other' target='_blank' rel='noopener noreferrer'><b>Other</b></a>, requires the applicant to provide the exact area of consulting and the applicant’s experience in the area.
          <br />
          <br />
          For more information on each specific Discipline, please click the links above.
      `,
		},
		{
			title: 'Can I apply for more than one Discipline?',
			content: `
          Consultants may apply to multiple disciplines but must satisfy the basic training requirements for each discipline.
      `,
		},
		{
			title: 'Why is there a basic training requirement?',
			content: `
      The Registry exists to fulfill the requirements of  <a href='https://statutes.capitol.texas.gov/docs/ED/htm/ED.37.htm' target='_blank' rel='noopener noreferrer'>
      <b>Texas Education Code 37.2091</b></a> and the Texas School Safety Center is required to verify the information provided by a person under Subsection (c) to confirm the person's qualifications and ability to provide school safety or security consulting services before adding the person to the registry.
      `,
		},
		{
			title: 'Can I market or promote my Registry status?',
			content: `
      Consultants can market their Registry status, including disciplines.  Misrepresentation of the Texas School Safety Center (TxSSC) is prohibited. Consultants shall not claim affiliation or endorsement by the TxSSC. Furthermore, consultants shall not display or advertise the TxSSC name, logo, or likeness without express written consent from the Director of the TxSSC.
      `,
		},
		{
			title: 'Will I be required to renew my registration?',
			content: `
      Discipline registration is valid for two years from issuance and consultants will be e-mailed instructions for registration renewal. Be prepared to reaffirm interest in disciplines as well as provide supporting documentation as necessary. Failure to respond to correspondence from the Texas School Safety Center may result in your removal from the Registry.
      `,
		},
		{
			title: 'How can I report a concern regarding a consultant?',
			content: `
      If there is reason to believe a consultant should not be included on the Registry for a disqualifying reason, anyone can file a complaint with the Texas School Safety Center via <a href='/contact/complaint' target='_blank' rel='noopener noreferrer'><b>Contact Us</b></a>. For more information on the background check process and the Summary of Disqualifiers, refer to <a href='/background/requirements' target='_blank' rel='noopener noreferrer'><b>Background Check Requirements</b></a> page.
      `,
		},
		{
			title:
				'Where can I find information on the legislation that established the Registry?',
			content: `
      As mandated by <a href='https://statutes.capitol.texas.gov/docs/ED/htm/ED.37.htm' target='_blank' rel='noopener noreferrer'><b>Texas Education Code 37.2091</b></a>, any individual providing school safety or security consulting services to school districts, open enrollment charter schools, or junior colleges in Texas must register with the Texas School Safety Center (TxSSC). These services include providing advice, information, recommendations, data collection, or safety and security audit services relevant to school safety and security.
      <br />
      <br />
      For more information on <a href='https://statutes.capitol.texas.gov/docs/ED/htm/ED.37.htm' target='_blank' rel='noopener noreferrer'>
      <b>Texas Education Code 37.2091</b></a>, refer to the <a href='/about/legislation' target='_blank' rel='noopener noreferrer'>
			<b>Legislation</b></a> page. 
      `,
		},
	];

	return (
		<div className='faqs'>
			<Masthead heading1='FAQs' heading2='For Consultants' />

			<ol className='expander'>
				{faqSections.map((section, index) => (
					<li key={index} className='toc-section'>
						<div className='toc-header' onClick={() => toggleSection(index)}>
							<h3>{section.title}</h3>
							<i
								className={`fa fa-chevron-${
									expandedSections[index] ? 'down' : 'up'
								}`}></i>
						</div>
						{expandedSections[index] && (
							<div
								className='expandable'
								dangerouslySetInnerHTML={{ __html: section.content }}></div>
						)}
					</li>
				))}
			</ol>
			<div className='tool-tip'>
				<p className='tip-text'>
					<i className='fa fa-arrow-up'></i> Click on the section title for more
					info.
				</p>
			</div>
		</div>
	);
};

export default Consultants;
