import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
	return (
		<Fragment>
			<div className='centered'>
				<h1>
					Welcome to the Texas School Safety and Security Consultant Registry
				</h1>
			</div>
			<p className='left-full'>
				Welcome to the Texas School Safety and Security Consultant Registry;
				commonly referred to as “The Registry.” The Registry is mandated by
				Texas Education Code 37.2091. For additional legal and legislative
				information refer to the legislation tab in the about section of this
				website. The Registry provides a list of contractors that have been
				vetted for litigation involving school safety and security and for
				disqualifying criminal history. Districts are responsible for evaluating
				performance, products, insurance documentation, and checking references.
				A list of consultants can be found by clicking{' '}
				<Link to='/search'>
					<b>Search Registry</b>
				</Link>
				.
			</p>
			<div className='home-page'>
				<Link to='/search'>
					<div className='find-consultant'>
						<i id='info' className='fa fa-search' aria-hidden='true'></i>
						<h2>Find a Consultant on the Registry</h2>
						<p>Need consultant services?</p>
						Search the Registry of vetted consultants.
					</div>
				</Link>

				<Link to='/application-process'>
					<div className='apply-consultant'>
						<i id='laptop' className='fa fa-laptop' aria-hidden='true'></i>
						<h2>Apply to Become a Consultant</h2>
						<p>Ready to apply?</p>
						Learn about the online application process.
					</div>
				</Link>
			</div>
		</Fragment>
	);
};

export default Home;
