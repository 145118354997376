import React from 'react';
import Masthead from '../../components/Masthead';

const EOP = () => {
	return (
		<div className='disciplines'>
			<Masthead
				heading1='Disciplines'
				heading2='Multi-Hazard Emergency Operations Plan (EOP) Discipline'
			/>

			<p className='left-full'>
				An EOP Consultant may review, develop, or modify EOPs and provide
				emergency management consulting for public school districts, charter
				schools, and junior colleges to meet the standards established by the
				TxSSC and in compliance with Chapter 37 of the Texas Education Code.
			</p>

			<div className='callout'>
				<h3>
					EOP Basic Training <em>(all applicants)</em>
				</h3>
				<ul>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://sslp.txssc.txstate.edu/'
							alt='Link to School Safety Learning Portal'
						>
							TxSSC Multi-Hazard Emergency Operations Plan (EOP) Development
							Series: Introductory
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://training.fema.gov/is/courseoverview.aspx?code=is-100.c'
							alt='FEMA IS-100: Introduction to the Incident Command System'
						>
							FEMA IS-100: Introduction to the Incident Command System
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://training.fema.gov/is/courseoverview.aspx?code=IS-700.b'
							alt='FEMA IS-700: An Introduction to the National Incident Management System'
						>
							FEMA IS-700: An Introduction to the National Incident Management
							System
						</a>
					</li>
				</ul>
				<h3>
					Complete one of the following FEMA trainings for Emergency Planning
					for Schools.
				</h3>
				<ul>
					<li>FEMA E/L0361: Multi-Hazard Emergency Planning for Schools</li>
					<li>
						FEMA E/L0362: Train-the-Trainer Multi-Hazard Emergency Planning for
						Schools
					</li>
					<li>FEMA G364: Multi-Hazard Emergency Planning for Schools</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://training.fema.gov/is/courseoverview.aspx?code=is-362.a'
							alt='FEMA IS-362.a: Multi-Hazard Emergency Planning for Schools '
						>
							FEMA IS-362.a: Multi-Hazard Emergency Planning for Schools
						</a>
					</li>
				</ul>
				<h3>
					Complete one of the following FEMA trainings for Emergency Management
					for Higher Education.
				</h3>
				<ul>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://training.fema.gov/hiedu/aemrc/eplanning/l363.aspx'
							alt='E/L0363: Multi-Hazard Emergency Management for Higher Education'
						>
							E/L0363: Multi-Hazard Emergency Management for Higher Education
						</a>
					</li>

					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://training.fema.gov/is/courseoverview.aspx?code=IS-363'
							alt='FEMA IS-363: Intro to Emergency Management for Higher Education'
						>
							FEMA IS-363: Intro to EM for Higher Education
						</a>
					</li>
				</ul>
				<h4 className='no-margin-bottom'>Evidence Required:</h4>
				<ul className='no-margin-top'>
					<li>Course certificates uploaded to Registry software</li>
				</ul>
			</div>

			<h3>Professional Qualifications</h3>
			<p className='left-full'>
				Consultants may choose to submit proof to add the following discipline
				qualifications to enhance their public profile. Once verified by TxSSC,
				consultant qualifications will be searchable and viewable on the
				Registry. While these qualifications are not mandatory, consultants may
				want to consider one or more of them as they may be used by districts to
				select consultants from the Registry.
			</p>

			<p className='left-full'>
				Districts are encouraged to use these additional qualifications to help
				decide which consultant may be best to perform services for the
				district.
			</p>

			<div className='callout'>
				<h3>Training and Certifications</h3>
				<ul>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://www.iaem.org/certification/intro'
							alt='Associate Emergency Manager, International Association of Emergency Managers '
						>
							Associate Emergency Manager, International Association of
							Emergency Managers{' '}
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://www.iaem.org/certification/intro'
							alt='Certified Emergency Manager, International Association of Emergency Managers'
						>
							Certified Emergency Manager, International Association of
							Emergency Managers
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://www.emat-tx.org/TEM'
							alt='Texas Emergency Manager, Emergency Management Association of Texas'
						>
							Texas Emergency Manager, Emergency Management Association of Texas
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://training.fema.gov/empp/basic.aspx'
							alt='National Emergency Management Basic Academy, Emergency Management Institute'
						>
							National Emergency Management Basic Academy, Emergency Management
							Institute
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://training.fema.gov/empp/advanced.aspx'
							alt='National Emergency Management Advanced Academy, Emergency Management Institute)'
						>
							National Emergency Management Advanced Academy, Emergency
							Management Institute
						</a>
					</li>
					<li>
						<i
							className='fa fa-external-link download-link'
							aria-hidden='true'
						></i>
						<a
							href='https://training.fema.gov/empp/executive.aspx'
							alt='National Emergency Management Executive Academy, Emergency Management Institute'
						>
							National Emergency Management Executive Academy, Emergency
							Management Institute
						</a>
					</li>
				</ul>

				<h4 className='no-margin-bottom'>Evidence Required:</h4>
				<ul className='no-margin-top'>
					<li>Certification uploaded to Registry software</li>
				</ul>
			</div>
		</div>
	);
};

export default EOP;
