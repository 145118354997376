import React from "react";
import Masthead from "../../components/Masthead";

const Complaint = () => {
  return (
    <div className="contact">

      <Masthead heading1="Contact Us" heading2="File a Complaint" />

      <div className="flex-container">
        <div className="flex-box">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

          <a href="https://forms.txssc.txstate.edu/registry/file-a-complaint">
            <h3>File a Complaint</h3>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Complaint;
