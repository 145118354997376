import React from 'react';
import Masthead from '../../components/Masthead';

const Info = () => {
	return (
		<div className='contact'>
			<Masthead heading1='Contact Us' heading2='General Contact Information' />

			<div className='flex-container'>
				<div className='flex-box'>
					<i className='fa fa-question-circle' aria-hidden='true'></i>

					<h3>For Registry Questions</h3>
					<a href='mailto:txssc_registry@txstate.edu'>
						txssc_registry@txstate.edu
					</a>
				</div>

				<div className='flex-box'>
					<i className='fa fa-question-circle' aria-hidden='true'></i>

					<h3>For Audit Questions</h3>
					<a href='mailto:txssc_audit_info@txstate.edu'>
						txssc_audit_info@txstate.edu
					</a>
				</div>

				<div className='flex-box'>
					<i className='fa fa-question-circle' aria-hidden='true'></i>

					<h3>For EOP Questions</h3>
					<a href='mailto:txssc_eop@txstate.edu'>txssc_eop@txstate.edu</a>
				</div>
			</div>
		</div>
	);
};

export default Info;
