import React, { Fragment } from "react";
import risingStarLogo from "../assets/images/rising_star_logo.png";
import txsscBWLogo from "../assets/images/txssc_b&w_logo.png";
import memberTheTxState from "../assets/images/member_the_tx_state.png";

const Footer = () => {
  return (
    <Fragment>
      <div className="footer-container" role="contentinfo">
        <div className="flex-item first">
          <a href="https://txssc.txstate.edu/">
            <img src={txsscBWLogo} alt="TxSSC Logo" />
          </a>
          <ul className="social">
            <li>
              <a href="http://twitter.com/TxSchoolSafety">
                <i className="fa fa-x-twitter"></i> <span>X/Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/txssc">
                <i className="fa fa-facebook-f"></i> <span>Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://forms.txssc.txstate.edu/contact/join">
                <i className="fa fa-envelope"></i> <span>Join Email List</span>
              </a>
            </li>
            <li>
              <a href="https://forms.txssc.txstate.edu/contact/us">
                <i className="fa fa-comment"></i> <span>Contact Us</span>
              </a>
            </li>
          </ul>
          <p className="learn-more">
            <a href="https://txssc.txstate.edu/about">Learn more About TxSSC</a>
          </p>
        </div>

        {/* <div className="flex-item middle">
          Sign up for Registry updates
          <a href="https://forms.txssc.txstate.edu/registry/registry-updates/">
            <div className="sign-up">
              <i className="fa fa-envelope" aria-hidden="true" />
              Sign Up
            </div>
          </a>
        </div> */}

        <div className="flex-item last">
          <a href="https://www.txstate.edu/">
            <div className="image-right">
              <img src={risingStarLogo} alt="texas state university logo" id="logo" />
              <img
                src={memberTheTxState}
                alt="Member of the TX State University System"
                id="member-img"
              />
            </div>
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
