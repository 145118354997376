import React from "react";
import Masthead from "../../components/Masthead";

const ApplicantAppeal = () => {
  return (
    <div className="background">
      <Masthead heading1="Background" heading2="Applicant Appeal Process" />

      <p className="left-full">
        Registry applicants will receive an email notification of their
        acceptance or denial via the Registry software.
      </p>

      <h2>Administrative Denial</h2>
      <div className="callout">
        <h3>
          An applicant may be administratively denied for reasons including, but
          not limited to, the following:{" "}
        </h3>
        <ul>
          <li>Providing expired documentation</li>
          <li>Failing to upload required documents</li>
          <li>Failing to complete the application process</li>
          <li>
            Failing to provide proof of basic training for a requested
            discipline.
          </li>
        </ul>
      </div>

      <h2>Administrative Appeal</h2>
      <p className="left-full">
        An applicant denied for an administrative reason may immediately reapply
        to the Registry without requesting an appeal.
      </p>

      <h2>Substantive Denial</h2>
      <div className="callout">
        <h3>
          An applicant may be substantively denied for reasons including, but
          not limited to, the following:
        </h3>
        <ul>
          <li>
            Litigation involving school safety and security consulting services,
          </li>
          <li>Disqualifying criminal history, </li>
          <li>Sex offender registry status,</li>
          <li>Status on the TEA Do Not Hire Registry, and </li>
          <li>
            Negative status on the State Board for Educator Certification (SBEC)
            educator certificate search, or equivalent.
          </li>
        </ul>
      </div>

      <h2>Substantive Appeal</h2>
      <p className="left-full">
        An applicant denied for substantive reasons may file an appeal within
        thirty (30) calendar days via the Registry software. The appeal will be
        reviewed by a TxSSC School Safety Readiness Supervisor within fifteen
        (15) business days of receipt of the appeal. The applicant will receive
        notification of the appeal determination via the Registry software.
      </p>
      <p className="left-full">
        An applicant may appeal the decision of the TxSSC School Safety
        Readiness Supervisor within thirty (30) calendar days of the date the
        appeal denial is sent via the Registry software. The Associate Director
        will render a final and binding decision concerning the appeal within
        fifteen (15) business days from the day of receipt of the appeal.
      </p>
      <p className="left-full">
        If a substantive denial is upheld after both appeals, the applicant is
        not eligible to reapply to the Registry for a minimum of one year, as
        determined by the Director of TxSSC, or the timeframe of the disqualifier,
        if applicable.
      </p>
      <p className="left-full">
        Failure to appeal within the stated timeframes will be considered a final
        substantive denial.
      </p>
    </div>
  );
};

export default ApplicantAppeal;
