import React from 'react';

const Masthead = ({ heading1, heading2 }) => {
	return (
		<div className='masthead'>
			<hgroup>
				<h1>{heading1}</h1>
				<h2>{heading2}</h2>
			</hgroup>
		</div>
	);
};

export default Masthead;
