import React, { useEffect, useRef } from 'react';
import { PENDING_LITIGATION_NOTE } from '../../Constants';
import ServiceDetails from './ServiceDetails';
import ContactInformation from './ContactInformation';
import AdditionalInformation from './AdditionalInformation';

const ShowContractor = ({ contractor, setShowIndividualContractor }) => {
	const ref = useRef();

	useEffect(() => {
		// Add no-scroll class to body when the modal is open
		document.body.classList.add('no-scroll');

		// Function to check if clicked outside of the modal
		const checkIfClickedOutside = (e) => {
			if (ref.current && !ref.current.contains(e.target)) {
				setShowIndividualContractor(false);
			}
		};

		// Event listener for click
		document.addEventListener('mousedown', checkIfClickedOutside);

		// Cleanup function
		return () => {
			// Remove event listener
			document.removeEventListener('mousedown', checkIfClickedOutside);
			// Remove no-scroll class when the modal closes
			document.body.classList.remove('no-scroll');
		};
	}, [setShowIndividualContractor]);

	return (
		<div className='contractor-details' id='to-print'>
			<div className='contractor-content' ref={ref}>
				<span
					className='close'
					onClick={() => setShowIndividualContractor(false)}>
					<i className='fa fa-times' aria-hidden='true' />
				</span>
				<i
					className='fa fa-print'
					aria-hidden='true'
					onClick={() => window.print()}
				/>

				<h2 className='header'>
					{`${contractor.public_first_name} ${contractor.public_last_name}`}
					{contractor.contact_details.public_org_affiliation === 'Company/Organization Affiliation Not Provided'
						? ''
						: ` - ${contractor.contact_details.public_org_affiliation}`}
				</h2>
				<div className='contact-addl-container'>
					{/* Contact Information */}
					<ContactInformation contractor={contractor} />
					{/* Additional Information */}
					<AdditionalInformation contractor={contractor} />
				</div>
				{/* Litigation Section */}
				<div className='litigations'>
					<div className='litigation'>
						<div className='litigation-info'>
							<h3>Current or Pending Civil Litigation</h3>
						</div>
						<h3>
							{contractor.additional_info.pending_litigation ? 'Yes' : 'No'}
						</h3>
						<div className='litigation-details'>
							<p>{PENDING_LITIGATION_NOTE}</p>
						</div>
					</div>
				</div>
				{/* Services Section */}
				<div className='services'>
					<h2>Services</h2>
					{contractor.is_audit_approved && (
						<ServiceDetails
							discipline={contractor.discipline_details.audit}
							disciplineType='audit'
						/>
					)}
					{contractor.is_eop_approved && (
						<ServiceDetails
							discipline={contractor.discipline_details.eop}
							disciplineType='eop'
						/>
					)}
					{contractor.is_sbta_approved && (
						<ServiceDetails
							discipline={contractor.discipline_details.sbta}
							disciplineType='sbta'
						/>
					)}
					{contractor.is_other_approved && contractor.discipline_details.other && (
						<div className='service'>
							<h3 className='discipline'>
								Other Safety and Security Discipline
							</h3>
							<div className='service-details'>
								<h4>Other Services:</h4>
								<p>
									{contractor.discipline_details.other.other_services.join(
										', '
									)}
								</p>
							</div>
						</div>
					)}
				</div>
			</div>
			{/* Print Styles */}
			<style type='text/css' media='print'>
				{`@media print {
          * { color: black !important; background: none !important; }
          .contractor-details { position: static; display: flex; padding-top: 0px !important; }
          .contractor-content { border: none !important; padding: 0; -ms-overflow-style: none; scrollbar-width: none; padding-top: 0px !important; }
          .contractor-content::-webkit-scrollbar { display: none; }
          .contact, .additional-info, .litigation, h2, .service { margin-bottom: 0px !important; }
          .service { page-break-inside: avoid; }
          .not-printable, #footer, #nav, .filters, .fa-print, .fa-times, .pagination-bar, .env-banner { display: none; }
        }`}
			</style>
		</div>
	);
};

export default ShowContractor;
