import { useFetch } from '../../hooks/useFetch';
// import Pie from '../../components/Pie';
import Bar from '../../components/Bar';
import Masthead from '../../components/Masthead';
import { API_BASE_URL } from '../../Constants';

const Dashboard = () => {
	const { data, isPending, error } = useFetch(`${API_BASE_URL}/contractors`);

	const uniqueConsultants = data?.contractors?.length;

	const findTotal = (acronym) => {
		return data?.contractors?.reduce((acc, contractor) => {
			if (contractor.approved_disciplines.includes(acronym)) {
				acc++;
			}
			return acc;
		}, 0);
	};

	const findPercentage = (totalAcronym) => {
		return Math.round((totalAcronym / data?.contractors?.length) * 100);
	};

	// find the total number of EOP, SBTA, Audit, and Other use the findTotal function
	const totalEOP = findTotal('EOP');
	const totalSBTA = findTotal('SBTA');
	const totalAudit = findTotal('AUDIT');
	const totalOther = findTotal('OTHER');

	// determine the percentages of the total submissions for each credential use the findPercentage function
	const percentEOP = findPercentage(totalEOP);
	const percentSBTA = findPercentage(totalSBTA);
	const percentAudit = findPercentage(totalAudit);
	const percentOther = findPercentage(totalOther);

	return (
		<div>
			{error && (
				<div className='dashboard'>
					<Masthead heading1='Dashboard' heading2='Registry Statistics' />
					<h2>Error: {error}</h2>
				</div>
			)}
			{isPending && (
				<div className='dashboard'>
					<Masthead heading1='Dashboard' heading2='Registry Statistics' />
					<h2>Loading...</h2>
				</div>
			)}
			{data && (
				<div className='dashboard'>
					<Masthead heading1='Dashboard' heading2='Registry Statistics' />
					<div className='total'>
						<h3 className='count-heading'>
							Number of Registered Consultants: {uniqueConsultants}
						</h3>
					</div>
					<div className='dashboard-counts'>
						<div className='count'>
							<h3 className='count-heading'>
								<span>EOP</span> Consultants:
							</h3>
							<h4 className='count-value'>{totalEOP}</h4>
							<Bar percentage={percentEOP} />
						</div>
						<div className='count'>
							<h3 className='count-heading'>
								<span>SBTA</span> Consultants:
							</h3>
							<h4 className='count-value'>{totalSBTA}</h4>
							<Bar percentage={percentSBTA} />
						</div>
						<div className='count'>
							<h3 className='count-heading'>
								<span>Audit</span> Consultants:
							</h3>
							<h4 className='count-value'>{totalAudit}</h4>
							<Bar percentage={percentAudit} />
						</div>
						<div className='count'>
							<h3 className='count-heading'>
								<span>Other</span> Consultants:
							</h3>
							<h4 className='count-value'>{totalOther}</h4>
							<Bar percentage={percentOther} />
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Dashboard;
