import './App.scss';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Components
import Footer from './components/Footer';
import Navigation from './components/Navigation';

// Home Page
import Home from './pages/Home';

// About
import ConsultantOverview from './pages/About/ConsultantOverview';
import DistrictOverview from './pages/About/DistrictOverview';
import Legislation from './pages/About/Legislation';
import ConsultantConduct from './pages/About/ConsultantConduct';
import Purpose from './pages/About/Purpose';
import Policies from './pages/About/Policies';

// Background
import ApplicantAppeal from './pages/Background/ApplicantAppeal';
import ConsultantAppeal from './pages/Background/ConsultantAppeal';
import Requirements from './pages/Background/Requirements';

// Contact
import Team from './pages/Contact/Team';
import Complaint from './pages/Contact/Complaint';
import Info from './pages/Contact/Info';

// Disciplines
import EOP from './pages/Disciplines/EOP';
import Other from './pages/Disciplines/Other';
import Audit from './pages/Disciplines/Audit';
import SBTA from './pages/Disciplines/SBTA';

// FAQs
import Consultants from './pages/Faqs/Consultants';
import Schools from './pages/Faqs/Schools';
import Fingerprinting from './pages/Faqs/Fingerprinting';

// Logins
import ApplicationProcess from './pages/Login/ApplicationProcess';

// Dashboard
import Dashboard from './pages/Dashboard/Dashboard';

import Search from './pages/Search/Search';

export default function App() {
	// determine if qual or not https://registry.txssc.qual.txstate.edu/
	const qual = window.location.href.includes('qual');
	const dev = window.location.href.includes('localhost');
	return (
		<Router>
			<div id='wrap'>
				{qual ? (
					<div className='env-banner qual'>Quality Assurance Environment</div>
				) : null}
				{dev ? <div className='env-banner dev'>Development</div> : null}
				<div id='nav'>
					<Navigation />
				</div>
				<main className='content'>
					<Switch>
						<Route path='/about/purpose' component={Purpose} />
						<Route
							path='/about/consultant-overview'
							component={ConsultantOverview}
						/>
						<Route
							path='/about/district-overview'
							component={DistrictOverview}
						/>
						<Route path='/about/legislation' component={Legislation} />
						<Route
							path='/about/consultant-conduct'
							component={ConsultantConduct}
						/>
						<Route path='/about/policies' component={Policies} />

						<Route path='/disciplines/audit' component={Audit} />
						<Route path='/disciplines/eop' component={EOP} />
						<Route path='/disciplines/sbta' component={SBTA} />
						<Route path='/disciplines/other' component={Other} />

						<Route path='/background/requirements' component={Requirements} />
						<Route
							path='/background/consultant-process'
							component={ConsultantAppeal}
						/>
						<Route
							path='/background/applicant-process'
							component={ApplicantAppeal}
						/>

						<Route path='/faqs/consultants' component={Consultants} />
						<Route path='/faqs/fingerprinting' component={Fingerprinting} />
						<Route path='/faqs/sd-charter-jc' component={Schools} />

						<Route path='/contact/team' component={Team} />
						<Route path='/contact/contact-info' component={Info} />
						<Route path='/contact/complaint' component={Complaint} />

						<Route path='/search' component={Search} />

						<Route path='/application-process' component={ApplicationProcess} />

						<Route path='/dashboard' component={Dashboard} />

						<Route path='/' exact component={Home} />

						<Route path='*' component={Home} />
					</Switch>
				</main>
			</div>

			<div id='footer'>
				<Footer />
			</div>
		</Router>
	);
}
