import React from 'react';
import Masthead from '../../components/Masthead';

const ApplicationProcess = () => {
	return (
		<div className='disciplines'>
			<Masthead heading1='About' heading2='Consultant Application Process' />

			<p className='left-full'>
				Each individual providing school safety or security consulting services
				in the state of Texas is required by{' '}
				<a
					href='https://statutes.capitol.texas.gov/Docs/ED/htm/ED.37.htm#37.2091'
					target='_blank'
					rel='noopener noreferrer'>
					<b>TEC 37.2091</b>
				</a>{' '}
				to register with the Texas School Safety Center (TxSSC) in accordance
				with requirements established by the TxSSC.
			</p>

			<p className='left-full'>
				The application process consists of two parts: the General Application
				and Discipline Application.
			</p>

			<p className='left-full'>
				The General Application can be accessed by clicking the “Begin General
				Application” button below. Upon receipt of a qualifying background
				check, the applicant will be provided a direct link to apply for a
				Discipline.
			</p>

			<p className='left-full'>
				During the application process, and as part of ongoing communication,
				you will receive emails from the TxSSC. Please ensure emails from the
				TxSSC are not classified as spam.
			</p>

			<div className='banner-list row lighten'>
				<h3 className='header spanning'>
					<small>General Application Process</small>
				</h3>

				<ul className='announcement-font standard no-margin-top'>
					<li>Click on the "Begin General Application" button below</li>
					<li>
						Provide the following information:
						<ul className='no-margin-top'>
							<li>Personal Information</li>
							<li>Contact Information</li>
							<li>Organization/Company Affiliation</li>
							<li>CMBL, HUB, & Veteran status (as applicable)</li>
							<li>Background Check Screening Information</li>
							<li>Pending Litigation Information (if applicable)</li>
						</ul>
					</li>
					<li>Complete the Application Acknowledgement</li>
					<li>Complete the Fingerprint Check Acknowledgement</li>
					<li>
						Ensure all fields in the General Application are completed as
						progress cannot be saved if you are unable to complete in a single
						session
					</li>
					<li>Select Submit</li>
				</ul>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					For detailed instructions, view the{' '}
					<a
						href='https://locker.txssc.txstate.edu/108631ffbe5a7d437a117717af9d3bc1/Step-by-Step-Guide-General-Application.pdf'
						target='_blank'
						rel='noopener noreferrer'>
						<b>General Application Step-by-Step Guide</b>
					</a>
				</p>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					Upon review and approval of your General Application, the FBI
					Fingerprint Instructions will be provided to the applicant via email.
				</p>

				<ul className='announcement-font standard no-margin-top'>
					<li>
						Submit fingerprints for a national criminal history background check
						as instructed in the FBI Fingerprint Instructions email from{' '}
						<a href='mailto:txssc_registry@txstate.edu'>
							<b>txssc_registry@txstate.edu</b>
						</a>
					</li>
				</ul>

				<p style={{ margin: '20px 0px 20px 10px' }}>
					Upon receipt of a qualifying background check, the link to the
					Discipline Application will be provided to the applicant via email.
				</p>
			</div>

			<div className='banner-list row lighten'>
				<h3 className='header spanning'>
					<small>Discipline Application Process</small>
				</h3>

				<ul className='announcement-font standard no-margin-top'>
					<li>
						Open the <b>“Discipline Application”</b> link provided by the TxSSC.
						If you have not received an email approving your General
						Application, you must wait to receive the email to begin the
						Discipline Application process.
					</li>
					<li>
						Provide the following information:
						<ul>
							<li>Registry Application Confirmation Code</li>
							<li>Personal Contact Information</li>
							<li>Contact Information for display on the Registry</li>
							<li>
								Education Service Center (ESC) Region(s) you intend to serve
							</li>
							<li>
								Discipline specific requirements:
								<ul>
									<li>
										<a
											href='/disciplines/audit'
											target='_blank'
											rel='noopener noreferrer'>
											<b>Audit</b>
										</a>
									</li>
									<li>
										<a
											href='/disciplines/eop'
											target='_blank'
											rel='noopener noreferrer'>
											<b>EOP</b>
										</a>
									</li>
									<li>
										<a
											href='/disciplines/sbta'
											target='_blank'
											rel='noopener noreferrer'>
											<b>SBTA</b>
										</a>
									</li>
									<li>
										<a
											href='/disciplines/other'
											target='_blank'
											rel='noopener noreferrer'>
											<b>Other</b>
										</a>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>
						Ensure all fields in the Discipline Application are completed as
						progress cannot be saved if you are unable to complete in a single
						session.
					</li>
					<li>Select Submit</li>
				</ul>

				<p style={{ margin: '20px 0px 20px 10px' }}>
					For detailed instructions, view the{' '}
					<a
						href='https://locker.txssc.txstate.edu/108631ffbe5a7d437a117717af9d3bc1/Step-by-Step-Guide-Discipline-Application.pdf'
						target='_blank'
						rel='noopener noreferrer'>
						<b>Discipline Application Step-by-Step Guide</b>
					</a>
				</p>
			</div>

			<div className='file-download-imp-btn' style={{ margin: '25px 0px' }}>
				<a
					href='https://forms.txssc.txstate.edu/registry/general-application'
					alt='begin general application'>
					{''}
				</a>
				<span className='link-and-fa'>
					<i className='fa fa-external-link' aria-hidden='true'></i>
					<span className='link'> Begin General Application</span>
				</span>

				<span className='file-type'>
					<i className='fa fa-globe' aria-hidden='true'></i>
				</span>
			</div>
		</div>
	);
};

export default ApplicationProcess;
