import React from 'react';
import Masthead from '../../components/Masthead';

const Legislation = () => {
	return (
		<div className='about'>
			<Masthead heading1='About' heading2='Legislation' />

			<h3 className='left-full'>
				Authority{' '}
				<a
					href='https://statutes.capitol.texas.gov/docs/ED/htm/ED.37.htm'
					target='_blank'
					rel='noopener noreferrer'>
					Texas Education Code 37.2091
				</a>
				:
			</h3>

			<h3>
				Sec. 37.2091. REGISTRY OF PERSONS PROVIDING SCHOOL SAFETY OR SECURITY
				CONSULTING SERVICES.
			</h3>

			<div className='law-ol-list'>
				<ol>
					<li>
						In this section, "school safety or security consulting services"
						includes any service provided to a school district, institution of
						higher education, district facility, or campus by a person
						consisting of advice, information, recommendations, data collection,
						or safety and security audit services relevant to school safety and
						security, regardless of whether the person is paid for those
						services.
					</li>
					<li>
						The center shall establish a registry of persons providing school
						safety or security consulting services in this state. <br />
						(b-1) A school district must confirm that a person is included in
						the registry established under Subsection (b) before the district
						may engage the person to provide school safety or security
						consulting services to the district.
					</li>

					<li>
						Each person providing school safety or security consulting services
						in this state shall register with the center in accordance with
						requirements established by the center. The requirements must
						include provisions requiring a person registering with the center to
						provide information regarding:
						<ol type='1' className='numerical'>
							<li>
								the person's background, education, and experience that are
								relevant to the person's ability to provide knowledgeable and
								effective school safety or security consulting services; and
							</li>
							<li>
								any complaints or pending litigation relating to the person's
								provision of school safety or security consulting services.
							</li>
						</ol>
					</li>
					<li>
						The center shall verify the information provided by a person under
						Subsection (c) to confirm the person's qualifications and ability to
						provide school safety or security consulting services before adding
						the person to the registry.
					</li>
					<li>
						The center shall include information regarding the registry,
						including the number of persons registered and the general degree of
						school safety or security experience possessed by those persons, in
						the biennial report required by Section{' '}
						<a
							href='http://www.statutes.legis.state.tx.us/GetStatute.aspx?Code=ED&Value=37.216'
							target='_blank'
							rel='noopener noreferrer'
							alt='section 37.216 link'>
							37.216
						</a>
						.
					</li>
				</ol>
			</div>

			<p className='left-full'>
				<b>Added by:</b>
			</p>
			<p className='left-full'>
				Acts 2009, 81st Leg., R.S., Ch. 1280 (H.B.{' '}
				<a
					href='http://www.legis.state.tx.us/tlodocs/81R/billtext/html/HB01831F.HTM'
					target='_blank'
					rel='noopener noreferrer'
					alt='H.B. 1831 link'>
					1831
				</a>
				), Sec. 6.09, eff. September 1, 2009.
			</p>
			<p className='left-full'>
				<b>Amended by:</b>
			</p>
			<p className='left-full'>
				Acts 2019, 86th Leg., R.S., Ch. 464 (S.B.{' '}
				<a
					href='http://www.legis.state.tx.us/tlodocs/86R/billtext/html/SB00011F.HTM'
					target='_blank'
					rel='noopener noreferrer'
					alt='S.B. 11 link'>
					11
				</a>
				), Sec. 16, eff. June 6, 2019.
			</p>
			<p className='left-full'>
				Acts 2023, 88th Leg., R.S., Ch. 896 (H.B{' '}
				<a
					href='https://capitol.texas.gov/tlodocs/88R/billtext/html/HB00003F.HTM'
					target='_blank'
					rel='noopener noreferrer'
					alt='hb 3 link'>
					3
				</a>
				), Sec. 18, eff. September 1, 2023.
			</p>
		</div>
	);
};

export default Legislation;
