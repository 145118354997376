import React from 'react';
import { AUDIT_NOTE } from '../../Constants';

const ServiceDetails = ({ discipline, disciplineType }) => {
    if (!discipline) return null;

    let disciplineTitle = disciplineType.toUpperCase();
    let experienceTitle;
    let experience = discipline[`${disciplineType}_experience`] || discipline['conducted_audits'] ? 'Yes' : 'No';
    let additionalNote = null;

    switch (disciplineType) {
        case 'audit':
						disciplineTitle = 'Audit';
            experienceTitle = 'Experience conducting school safety and security audits';
            if (!discipline['conducted_audits']) {
                additionalNote = <p className='audit-note'>{AUDIT_NOTE}</p>;
            }
            break;
        case 'eop':
            experienceTitle = 'Experience creating or revising multi-hazard EOPs';
            break;
        case 'sbta':
            experienceTitle = 'Experience as a mental health or education professional';
            break;
				default:
						return null;
    }

    const trainingCerts = discipline[`${disciplineType}_training_and_certs`] || [];
    const licenses = discipline[`${disciplineType}_licenses`] || [];

    return (
        <div className='service'>
            <h3 className='discipline'>{disciplineTitle} Discipline</h3>
            <div className='service-details'>
                {trainingCerts.length > 0 && (
                    <div className='training'>
                        <h4>{disciplineTitle} Discipline Training and Certifications:</h4>
                        {trainingCerts.map((tc, idx) => <p key={idx}>{tc}</p>)}
                    </div>
                )}
                {licenses.length > 0 && (
                    <div className='license'>
                        <h4>{disciplineTitle} Discipline Professional License:</h4>
                        <p>{licenses.join(', ')}</p>
                    </div>
                )}
                {experience && (
                    <div className='experience'>
                        <h4>{experienceTitle}: <span>{experience}</span></h4>
                    </div>
                )}
                {additionalNote}
            </div>
        </div>
    );
};

export default ServiceDetails;