import React from 'react';

const AdditionalInformation = ({ contractor }) => {
    if (!contractor) return null;

    const { years_of_experience, esc_regions } = contractor.contact_details;
    const { hub, military_veteran, cmbl } = contractor.additional_info;

    return (
        <div className='additional-info'>
            <h3>Additional Information</h3>
            <div className='additional-details'>
                {years_of_experience && (
                    <h4>Years of Experience: <span>{years_of_experience}</span></h4>
                )}
                <h4>HUB: <span>{hub ? 'Yes' : 'No'}</span></h4>
                <h4>Military Veteran: <span>{military_veteran ? 'Yes' : 'No'}</span></h4>
                <h4>CMBL Vendor: <span>{cmbl ? 'Yes' : 'No'}</span></h4>
                <h4>Region(s) Served: <span>{esc_regions.join(', ')}</span></h4>
            </div>
        </div>
    );
};

export default AdditionalInformation;