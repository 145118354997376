import React from 'react';
import Masthead from '../../components/Masthead';
import useExpander from '../../hooks/useExpander';

function Fingerprinting() {
	const [expandedSections, toggleSection] = useExpander();

	const faqSections = [
		{
			title:
				'How do my fingerprints get sent to the Texas School Safety Center?',
			content: `
			IdentoGo, the Texas Department of Public Safety (DPS) fingerprint vendor, is the sole company DPS contracts with to accept electronic fingerprints for the purpose of criminal history checks. Upon review and approval of a General Application, the FBI Fingerprint Instructions will be provided to the applicant via email which will include the TxSSC service code. The service code allows the TxSSC to be notified that your FBI fingerprint results are available for review through the DPS Secure Site. 
			<br />
			<br />
			<b>Background checks conducted for previous or current employment cannot be considered</b>. If you are not located in Texas, please reach out to <a href='mailto:txssc_registry@txstate.edu'><b>txssc_registry@txstate.edu</b></a> to request additional information.
		`,
		},
		{
			title: 'How do I schedule a fingerprint appointment?',
			content: `
			To schedule a fingerprinting appointment with IdentoGo, you must utilize the service code for the Texas School Safety Center (TxSSC). The FBI Fingerprint Instructions will be provided to the applicant via email upon review and approval of a General Application. These step-by-step instructions include how to schedule your appointment, fee information, what to bring to your appointment, the Service Code which is unique to the TxSSC and allows the TxSSC to be notified that your fingerprint results are available for review through the DPS Secure Site.
		`,
		},
		{
			title: 'What is the cost for an FBI fingerprint check?',
			content: `
			The total fingerprinting fee is $38.25 for School Safety and Security Consultant Registry applicants. The fingerprinting fee is paid directly to the fingerprinting vendor, and must be paid with a business check, money order, or credit card.
			`,
		},
		{
			title:
				'How long does it take for the Texas School Safety Center to process my fingerprint results?',
			content: `
			The TxSSC typically receives fingerprint results from DPS within 5 business days if the appointment was completed in Texas. If you have completed a fingerprinting appointment but have not received correspondence from the TxSSC with next steps within ten business days, please reach out to <a href='mailto:txssc_registry@txstate.edu'><b>txssc_registry@txstate.edu</b></a>.
		`,
		},
		{
			title:
				'Where can I find more information on the Background Check requirements?',
			content: `
			For more information on the background check process and the Summary of Disqualifiers, refer to <a href='/background/requirements'><b>Background Check Requirements</b></a> page.
		`,
		},
	];

	return (
		<div className='faqs'>
			<Masthead heading1='FAQs' heading2='FBI Fingerprinting Process' />

			<ol className='expander'>
				{faqSections.map((section, index) => (
					<li key={index} className='toc-section'>
						<div className='toc-header' onClick={() => toggleSection(index)}>
							<h3>{section.title}</h3>
							<i
								className={`fa fa-chevron-${
									expandedSections[index] ? 'down' : 'up'
								}`}></i>
						</div>
						{expandedSections[index] && (
							<div
								className='expandable'
								dangerouslySetInnerHTML={{ __html: section.content }}></div>
						)}
					</li>
				))}
			</ol>
			<div className='tool-tip'>
				<p className='tip-text'>
					<i className='fa fa-arrow-up'></i> Click on the section title for more
					info.
				</p>
			</div>
		</div>
	);
}

export default Fingerprinting;
