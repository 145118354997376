import React from 'react';
import Masthead from '../../components/Masthead';
import useExpander from '../../hooks/useExpander';

const Schools = () => {
	const [expandedSections, toggleSection] = useExpander();

	const faqSections = [
		{
			title:
				'Does the Texas School Safety Center (TxSSC) recommend consultants on the registry?',
			content: `
			The TxSSC does not endorse or recommend any consultants on the Registry. We carefully screen applicants against specific background disqualifiers in line with the Texas Education Agency and the Texas Department of Public Safety. 
			`,
		},
		{
			title:
				"How do we know we are selecting the best consultant for our district's needs?",
			content: `
			It is the responsibility of the hiring district to ensure the consultant can perform the work needed by the district to the standards required by the district. Districts are responsible for evaluating performance, products, insurance documentation, and checking references.
			`,
		},
		{
			title: 'What is a Discipline?',
			content: `
           Registry applicants are required to choose one or more disciplines for which they would like to provide consulting services to school districts, charter schools, or public junior colleges in Texas. The basic training requirements are specific to the Discipline being sought and documentation must be provided to show the applicant has met all requirements. The TxSSC has pre identified optional qualifications that applicants can demonstrate their skillset to searchers if supporting documentation is provided by the applicant.
					<br />
					<br />
					The Registry offers four separate Disciplines. The three distinct disciplines include <a href='/disciplines/audit' target='_blank' rel='noopener noreferrer'><b>Audit</b></a>, Multi-Hazard Emergency Operations Plan (<a href='/disciplines/eop' target='_blank' rel='noopener noreferrer'><b>EOP</b></a>) and School Behavioral Threat Assessment (<a href='/disciplines/sbta' target='_blank' rel='noopener noreferrer'><b>SBTA</b></a>). The comprehensive discipline, <a href='/disciplines/other' target='_blank' rel='noopener noreferrer'><b>Other</b></a>, requires the applicant to provide the exact area of consulting and the applicant’s experience in the area.
          <br />
          <br />
          For more information on each specific Discipline, please click the links above.
      `,
		},
		{
			title: 'What is the protocol if a consultant gets arrested?',
			content: `
			The Texas School Safety Center receives notifications from the Texas Department of Public Safety if anything changes regarding the status of a consultant’s background check.  If it is an offense that would disqualify them from being included on the Registry, they will be removed with an opportunity for appeal. 
			`,
		},
		{
			title:
				'What kind of attributes will be available when I search for a consultant?',
			content: `
			Districts can search the Registry with the filters of Services Provided (Discipline), ESC region served, optional Trainings, Certifications, and Licenses. Additionally, statuses such as veteran, HUB, CMBL vendor are displayed in each consultant’s profile. 
			`,
		},
		{
			title: 'How do I file a complaint regarding a consultant?',
			content: `
      If there is reason to believe a consultant should not be included on the Registry for a disqualifying reason, anyone can file a complaint with the Texas School Safety Center via <a href='/contact/complaint' target='_blank' rel='noopener noreferrer'><b>Contact Us</b></a>. For more information on the background check process and the Summary of Disqualifiers, refer to <a href='/background/requirements' target='_blank' rel='noopener noreferrer'><b>Background Check Requirements</b></a> page.
      `,
		},
		{
			title:
				'What are examples of professionals that are NOT required to register with the TxSSC?*',
			content: `
					The following professionals are not required to register:
					<br />
          <ul>
            <li>District Employees (Employees carrying out their assigned job duties for their employing district.)</li>
            <li>Education Service Center Specialists (Employees carrying out their assigned job duties for their assigned region.)</li>
            <li>School Marshals</li>
            <li>Guardians</li>
            <li>Private Security Guards</li>
            <li>School Based Law Enforcement Personnel </li>
            <li>DPS Certified School Safety Certification Instructors</li>
            <li>Architects</li>
            <li>First Responder with Jurisdiction</li>
          </ul>
          <p>
						* This list is not exhaustive. Please reach out to <a href='mailto:txssc_registry@txstate.edu'><b>txssc_registry@txstate.edu</b></a> if you have questions regarding the requirements for being on the Registry
					</p>
      `,
		},
		{
			title:
				'Where can I find information on the legislation that established the Registry?',
			content: `
      As mandated by <a href='https://statutes.capitol.texas.gov/docs/ED/htm/ED.37.htm' target='_blank' rel='noopener noreferrer'><b>Texas Education Code 37.2091</b></a>, any individual providing school safety or security consulting services to school districts, open enrollment charter schools, or junior colleges in Texas must register with the Texas School Safety Center (TxSSC). These services include providing advice, information, recommendations, data collection, or safety and security audit services relevant to school safety and security.
      <br />
      <br />
      For more information on <a href='https://statutes.capitol.texas.gov/docs/ED/htm/ED.37.htm' target='_blank' rel='noopener noreferrer'>
      <b>Texas Education Code 37.2091</b></a>, refer to the <a href='/about/legislation' target='_blank' rel='noopener noreferrer'>
			<b>Legislation</b></a> page. 
      `,
		},
	];

	return (
		<div className='faqs'>
			<Masthead
				heading1='FAQs'
				heading2='School Districts, Charters, and Junior Colleges FAQs'
			/>

			<ol className='expander'>
				{faqSections.map((section, index) => (
					<li key={index} className='toc-section'>
						<div className='toc-header' onClick={() => toggleSection(index)}>
							<h3>{section.title}</h3>
							<i
								className={`fa fa-chevron-${
									expandedSections[index] ? 'down' : 'up'
								}`}></i>
						</div>
						{expandedSections[index] && (
							<div
								className='expandable'
								dangerouslySetInnerHTML={{ __html: section.content }}></div>
						)}
					</li>
				))}
			</ol>
			<div className='tool-tip'>
				<p className='tip-text'>
					<i className='fa fa-arrow-up'></i> Click on the section title for more
					info.
				</p>
			</div>
		</div>
	);
};

export default Schools;
