import React from 'react';
import Masthead from '../../components/Masthead';

const Purpose = () => {
	return (
		<div className='about'>
			<Masthead heading1='About' heading2='Purpose' />

			<p>
				<span style={{ fontWeight: '600' }}>Purpose</span> -The Texas School
				Safety and Security Consultant Registry exists to fulfill the
				requirements of{' '}
				<a
					href='https://statutes.capitol.texas.gov/Docs/ED/htm/ED.37.htm#37.2091'
					target='_blank'
					rel='noopener noreferrer'>
					<strong>Texas Education Code 37.2091</strong>
				</a>{' '}
				by providing a registry of persons providing school safety or security
				consulting services in Texas.
			</p>

			<p>
				Consultants who provide school safety or security consulting services to
				K-12 school districts or junior colleges in the state of Texas are
				required to register with the Texas School Safety Center (TxSSC). School
				safety and security consulting services consist of{' '}
				<strong>
					advice, information, recommendations, data collection, or safety and
					security audit services
				</strong>{' '}
				relevant to school safety and security.
			</p>

			<p>
				The following professionals are <b>NOT</b> required to register with the
				TxSSC:
			</p>
			<ul>
				<li>
					District Employees (Employees carrying out their assigned job duties
					for their employing district.)
				</li>
				<li>
					Education Service Center Specialists (Employees carrying out their
					assigned job duties for their assigned region.)
				</li>
				<li>School Marshals</li>
				<li>Guardians</li>
				<li>Private Security Guards</li>
				<li>School Based Law Enforcement Personnel</li>
				<li>DPS Certified School Safety Certification Instructors</li>
				<li>Architects</li>
				<li>First Responder with Jurisdiction</li>
			</ul>
		</div>
	);
};

export default Purpose;
