import React from 'react';
import Masthead from '../../components/Masthead';

const ConsultantOverview = () => {
	return (
		<div className='about'>
			<Masthead heading1='About' heading2='Consultant Overview' />
			<p className='left-full'>
				The Texas School Safety and Security Consultant Registry (Registry)
				consists of individual consultants who have been vetted to provide
				school safety or security consulting services in Texas. The Registry is
				not company based but consultants may list the name of the company they
				are affiliated with during the registration process. School districts
				are required to confirm that <b>any individual</b> providing safety or
				security consulting services are included in the Registry, regardless of
				the individual's company affiliation.
			</p>

			<p>
				Consultants are subject to ongoing review of qualifications and the
				TxSSC may require individuals to update their information as needed to
				comply with legislative updates, system updates, and other Registry
				requirements. Individuals on the Registry may also be required to meet
				continuing education requirements to maintain their status on the
				Registry.
			</p>

			<div className='callout'>
				<h3>Basic requirements for inclusion on the Registry:</h3>
				<ul>
					<li>
						Must be at least 18 years of age, as defined by Civil Practice and
						Remedies Code 129.001;
					</li>
					<li>
						Must follow the application process outlined in the{' '}
						<a
							href='/application-process'
							target='_blank'
							rel='noopener noreferrer'>
							Consultant Application Process
						</a>
						.
					</li>
					<li>
						Must pass a background check;{' '}
						<em>
							<b>AND</b>
						</em>
					</li>
					<li>
						Must meet the basic training requirements for each discipline being
						sought by the applicant.
					</li>
				</ul>
			</div>

			<p className='left-full'>
				Upon completion of a qualifying background check, applicants must apply
				to one or more of the specific disciplines listed below:
			</p>

			<ol>
				<li>
					<a
						href='/disciplines/audit'
						target='_blank'
						rel='noopener noreferrer'>
						<b>Audit Discipline</b>
					</a>
				</li>
				<li>
					<a href='/disciplines/eop' target='_blank' rel='noopener noreferrer'>
						<b>Multi-Hazard Emergency Operations Plan (EOP) Discipline</b>
					</a>
				</li>

				<li>
					<a href='/disciplines/sbta' target='_blank' rel='noopener noreferrer'>
						<b>School Behavioral Threat Assessment (SBTA) Discipline</b>
					</a>
				</li>
				<li>
					<a
						href='/disciplines/other'
						target='_blank'
						rel='noopener noreferrer'>
						<b>Other Safety and Security Discipline</b>
					</a>
				</li>
			</ol>

			<p className='left-full'>
				Additionally, applicants will select the region(s) of the state where
				they are willing to provide services. These regions are defined by the
				State Board of Education and can be viewed below:
			</p>

			<div className='embed-container'>
				<iframe
					width='500'
					height='400'
					frameBorder='0'
					scrolling='no'
					marginHeight='0'
					marginWidth='0'
					title='Registry Regions Website'
					src='//txst.maps.arcgis.com/apps/Embed/index.html?webmap=da26b3ef31ac45c98cbfde2c51ef9ba2&extent=-108.4606,21.5679,-92.7721,37.8335&zoom=true&previewImage=false&scale=true&search=true&searchextent=true&disable_scroll=true&theme=dark'
				/>
			</div>
		</div>
	);
};

export default ConsultantOverview;
