import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Navigation() {
	const [newPage, setNewPage] = useState(false);

	useEffect(() => {
		// If navigated to a new page, close all navigation.
		if (newPage) {
			expandMobileMenu({ target: document.querySelector('.fa-chevron-up') });
			let allMinuses = document.querySelectorAll('.menu-span');
			for (let i = 0; i < allMinuses.length; i++) {
				let minus = allMinuses[i];
				if (minus.children[0].className.includes('fa-minus'))
					expandSubNav({ currentTarget: minus });
			}
		}
		setNewPage(false);
	}, [newPage]);

	const expandMobileMenu = (event) => {
		let target = event.target;

		let mobileMenu = document.querySelector('.mobile-menu');
		if (mobileMenu.style.display === 'block') {
			mobileMenu.style.display = 'none';
		} else {
			mobileMenu.style.display = 'block';
		}

		// For transition...
		if (mobileMenu.style.maxHeight) {
			mobileMenu.style.maxHeight = null;
		} else {
			mobileMenu.style.maxHeight = mobileMenu.scrollHeight + 'px';
		}

		// Change icon from menu bars to up arrow at bottom of div.
		if (target.className === 'fa fa-bars') {
			target.className = 'fa fa-chevron-up';
			target.style.position = 'absolute';
			target.style.bottom = '5px';
			target.style.left = '46.5%';
			target.style.fontSize = '19px';
		} else {
			target.className = 'fa fa-bars';
			target.style.position = '';
			target.style.bottom = '';
			target.style.left = '';
		}
	};

	const expandSubNav = (event) => {
		let target = event.currentTarget.children[0];
		// toggle the + or - sign on expansion
		if (target.className === 'fa fa-plus toggle-sub-nav') {
			target.className = 'fa fa-minus toggle-sub-nav';
		} else {
			target.className = 'fa fa-plus toggle-sub-nav';
		}

		// Show the subNav menu
		let subNav = target.parentElement.nextElementSibling;
		if (subNav.style.display === 'block') {
			subNav.style.margin = '10px 0px -15px 0px';
			subNav.style.display = 'none';
		} else {
			subNav.style.margin = '10px 0px 0px 0px';
			subNav.style.display = 'block';
		}

		let mobileMenu = document.querySelector('.mobile-menu');

		// add scrollHeight to the mobileMenu and the subNav onClick
		if (subNav.style.maxHeight) {
			subNav.style.maxHeight = null;
		} else {
			subNav.style.maxHeight = subNav.scrollHeight + 'px';
			mobileMenu.style.maxHeight =
				mobileMenu.scrollHeight + subNav.scrollHeight + 'px';
		}
	};

	return (
		<header>
			<div id='banner'>
				<Link to='/'>
					<div id='banner-img'>
						<div id='banner-text'>
							TEXAS SCHOOL SAFETY & SECURITY CONSULTANT REGISTRY
						</div>
					</div>
					<i className='fa fa-home' aria-hidden='true'></i>
				</Link>
			</div>

			<div className='mobile-dropdown'>
				<i
					className='fa fa-bars'
					aria-hidden='true'
					onClick={expandMobileMenu}></i>
				<div className='mobile-menu'>
					<ul className='navigation'>
						<li className='mobile-dropdown'>
							<div className='menu-span' onClick={expandSubNav}>
								About
								<i className='fa fa-plus toggle-sub-nav' aria-hidden='true'></i>
							</div>
							<ul className='sub-nav'>
								<Link to='/about/purpose'>
									<li onClick={() => setNewPage(true)}>Purpose</li>
								</Link>
								<Link to='/about/consultant-overview'>
									<li onClick={() => setNewPage(true)}>Consultant Overview</li>
								</Link>
								<Link to='/application-process'>
									<li onClick={() => setNewPage(true)}>
										Consultant Application Process
									</li>
								</Link>
								<Link to='/about/district-overview'>
									<li onClick={() => setNewPage(true)}>District Overview</li>
								</Link>
								<Link to='/about/legislation'>
									<li onClick={() => setNewPage(true)}>Legislation</li>
								</Link>
								<Link to='/about/consultant-conduct'>
									<li onClick={() => setNewPage(true)}>Consultant Conduct</li>
								</Link>
								<Link to='/about/policies'>
									<li onClick={() => setNewPage(true)}>
										Policies and Procedures Manual
									</li>
								</Link>
							</ul>
						</li>

						<li className='mobile-dropdown'>
							<div className='menu-span' onClick={expandSubNav}>
								Disciplines
								<i className='fa fa-plus toggle-sub-nav' aria-hidden='true'></i>
							</div>
							<ul className='sub-nav'>
								<Link to='/disciplines/audit'>
									<li onClick={() => setNewPage(true)}>Audit Discipline</li>
								</Link>
								<Link to='/disciplines/eop'>
									<li onClick={() => setNewPage(true)}>
										Emergency Operations Plan (EOP) Discipline
									</li>
								</Link>
								<Link to='/disciplines/sbta'>
									<li onClick={() => setNewPage(true)}>
										School Behavioral Threat Assessment (SBTA) Discipline
									</li>
								</Link>
								<Link to='/disciplines/other'>
									<li onClick={() => setNewPage(true)}>
										Other Safety and Security Discipline
									</li>
								</Link>
							</ul>
						</li>

						<li className='mobile-dropdown'>
							<div className='menu-span' onClick={expandSubNav}>
								Background
								<i className='fa fa-plus toggle-sub-nav' aria-hidden='true'></i>
							</div>
							<ul className='sub-nav'>
								<Link to='/background/requirements'>
									<li onClick={() => setNewPage(true)}>
										Background Check Requirements
									</li>
								</Link>
								<Link to='/background/applicant-process'>
									<li onClick={() => setNewPage(true)}>
										Applicant Appeal Process
									</li>
								</Link>
								<Link to='/background/consultant-process'>
									<li onClick={() => setNewPage(true)}>
										Consultant Appeal Process
									</li>
								</Link>
							</ul>
						</li>

						<li className='mobile-dropdown'>
							<div className='menu-span' onClick={expandSubNav}>
								FAQs
								<i className='fa fa-plus toggle-sub-nav' aria-hidden='true'></i>
							</div>
							<ul className='sub-nav'>
								<Link to='/faqs/consultants'>
									<li onClick={() => setNewPage(true)}>For Consultants</li>
								</Link>
								<Link to='/faqs/fingerprinting'>
									<li onClick={() => setNewPage(true)}>
										FBI Fingerprinting Process
									</li>
								</Link>
								<Link to='/faqs/sd-charter-jc'>
									<li onClick={() => setNewPage(true)}>
										For School Districts, Charters, and Junior Colleges
									</li>
								</Link>
							</ul>
						</li>

						<li className='mobile-dropdown'>
							<div className='menu-span' onClick={expandSubNav}>
								Contact Us
								<i className='fa fa-plus toggle-sub-nav' aria-hidden='true'></i>
							</div>
							<ul className='sub-nav' style={{ width: '100%' }}>
								<Link to='/contact/team'>
									<li onClick={() => setNewPage(true)}>Registry Team</li>
								</Link>
								<Link to='/contact/contact-info'>
									<li onClick={() => setNewPage(true)}>
										General Contact Information
									</li>
								</Link>
								<Link to='/contact/complaint'>
									<li onClick={() => setNewPage(true)}>File a Complaint</li>
								</Link>
							</ul>
						</li>

						<Link to='/search'>
							<li id='search'>
								<div>Search Registry</div>
							</li>
						</Link>
						<Link to='/application-process'>
							<li id='consultant-login'>
								<div>Apply Now</div>
							</li>
						</Link>
					</ul>
				</div>
			</div>

			<nav className='navigation-desktop'>
				<ul className='navigation'>
					<li className='dropdown'>
						<span style={{ cursor: 'default' }}>About</span>
						<ul className='sub-nav'>
							<li>
								<Link to='/about/purpose'>Purpose</Link>
							</li>
							<li>
								<Link to='/about/consultant-overview'>Consultant Overview</Link>
							</li>
							<li>
								<Link to='/application-process'>
									Consultant Application Process
								</Link>
							</li>
							<li>
								<Link to='/about/district-overview'>District Overview</Link>
							</li>
							<li>
								<Link to='/about/legislation'>Legislation</Link>
							</li>
							<li>
								<Link to='/about/consultant-conduct'>Consultant Conduct</Link>
							</li>
							<li>
								<Link to='/about/policies'>Policies and Procedures Manual</Link>
							</li>
						</ul>
					</li>

					<li className='dropdown'>
						<span style={{ cursor: 'default' }}>Disciplines</span>
						<ul className='sub-nav'>
							<li>
								<Link to='/disciplines/audit'>Audit Discipline</Link>
							</li>
							<li>
								<Link to='/disciplines/eop'>
									Emergency Operations Plan (EOP) Discipline
								</Link>
							</li>
							<li>
								<Link to='/disciplines/sbta'>
									School Behavioral Threat Assessment (SBTA) Discipline
								</Link>
							</li>
							<li>
								<Link to='/disciplines/other'>
									Other Safety and Security Discipline
								</Link>
							</li>
						</ul>
					</li>

					<li className='dropdown'>
						<span style={{ cursor: 'default' }}>Background</span>
						<ul className='sub-nav'>
							<li>
								<Link to='/background/requirements'>
									Background Check Requirements
								</Link>
							</li>
							<li>
								<Link to='/background/applicant-process'>
									Applicant Appeal Process
								</Link>
							</li>
							<li>
								<Link to='/background/consultant-process'>
									Consultant Appeal Process
								</Link>
							</li>
						</ul>
					</li>

					<li className='dropdown'>
						<span style={{ cursor: 'default' }}>FAQs</span>
						<ul className='sub-nav'>
							<li>
								<Link to='/faqs/consultants'>For Consultants</Link>
							</li>
							<li>
								<Link to='/faqs/fingerprinting'>
									FBI Fingerprinting Process
								</Link>
							</li>
							<li>
								<Link to='/faqs/sd-charter-jc'>
									For School Districts, Charters, and Junior Colleges
								</Link>
							</li>
						</ul>
					</li>

					<li className='dropdown'>
						<span style={{ cursor: 'default' }}>Contact Us</span>
						<ul className='sub-nav'>
							<li>
								<Link to='/contact/team'>Registry Team</Link>
							</li>
							<li>
								<Link to='/contact/contact-info'>
									General Contact Information
								</Link>
							</li>
							<li>
								<Link to='/contact/complaint'>File a Complaint</Link>
							</li>
						</ul>
					</li>

					<li id='search'>
						<span>
							<Link to='/search'>Search Registry</Link>
						</span>
					</li>
					<li id='consultant-login'>
						<span>
							<Link to='/application-process'>Apply Now</Link>
						</span>
					</li>
				</ul>
			</nav>
		</header>
	);
}
