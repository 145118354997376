import React from 'react';
import Masthead from '../../components/Masthead';

const ConsultantConduct = () => {
	return (
		<div className='about'>
			<Masthead heading1='About' heading2='Consultant Conduct' />

			<h2>General Conduct</h2>

			<p className='left-full'>
				Consultants shall abide by all legislative mandates and requirements set
				forth by the Texas School Safety Center (TxSSC). Consultants may be
				removed from the Registry for a violation of a legislative mandate or
				TxSSC policies and procedures, including, but not limited to, the
				following items referenced below.
			</p>

			<h2>Misrepresentation</h2>

			<p className='left-full'>
				Consultants shall not claim affiliation or endorsement by the TxSSC.
				Consultants shall not display or advertise the TxSSC name, logo, or
				likeness without express written consent from the Director of the TxSSC.
				Misrepresentation does not preclude a registered consultant from
				advertising their status, including disciplines, on the Registry.
			</p>

			<h2>Disciplines</h2>

			<p className='left-full'>
				Consultants must satisfy the requirements for each discipline in which
				they perform services. Consultants performing work outside of their
				registered discipline(s) are subject to suspension or revocation from
				the Registry.
			</p>

			<h2>Contact Information</h2>

			<p className='left-full'>
				During the General Application process, consultants <b>are</b> required
				to provide their contact information. It is the consultant's
				responsibility to ensure that the TxSSC has current contact information.
				Consultants will be placed on administrative suspension if the TxSSC
				cannot contact them.
			</p>

			<h2>Company Registration Prohibited</h2>

			<p className='left-full'>
				The Registry is comprised of persons providing school safety or
				security consulting services in Texas. Each individual providing school
				safety or security consulting services in the state of Texas is required
				by TEC 37.2091 to register with the TxSSC.
			</p>

			<p className='left-full'>
				Companies or organizations cannot apply for inclusion on the Registry.
				The Registry exists to fulfill the requirements of{' '}
				<a
					href='https://statutes.capitol.texas.gov/Docs/ED/htm/ED.37.htm#37.2091'
					target='_blank'
					rel='noopener noreferrer'>
					<b>Texas Education Code 37.2091</b>
				</a>{' '}
				by providing a registry of <b>persons</b> providing school safety and
				security consulting services in Texas.
			</p>

			<p className='left-full'>
				Each person performing school safety or security consulting services,
				whether on behalf of their self, a company, or an organization must
				become a registered consultant regardless of whether the person is paid
				for those services.
			</p>

			<h2>Policies and Procedures</h2>

			<p className='left-full'>
				The TxSSC reserves the right to revise and update Registry policies and
				procedures as necessary. For more information, refer to the{' '}
				<a href='/about/policies' target='_blank' rel='noopener noreferrer'>
					<b>Policies and Procedures Manual</b>
				</a>
				.
			</p>
		</div>
	);
};

export default ConsultantConduct;
